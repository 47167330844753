<template>
    <div class="mainbox">

        <div class="detail">
            <div class="detail_list">
                <div v-for="(item, index) in lists" :key="index" class="detail_item">
                    <div class="item_left">
                        <div style="flex-shrink: 0;" class="i_img">
                            <img :src="item.cover" alt="">
                        </div>
                        <div v-if="projectType == 0 || projectType == 5" class="item_detail">
                            <div class="i_til">{{ item.name }}</div>
                            <div v-if="item.projectType == 0" class="i_tils elp"> <span>招生计划：</span>{{ item.enrollPlan
                                }} 人</div>
                            <div v-if="item.projectType == 0" class="i_tils elp"> <span>学习形式：</span>{{ item.studyType }}
                            </div>
                            <div v-if="item.projectType == 0" class="i_tils elp"> <span>招生专业：</span>{{
                                item.majorNameList }}</div>
                            <div v-if="item.projectType == 5" class="i_tils elp"> <span>招生对象：</span>{{
                                item.enrollObject }}</div>
                            <div v-if="item.projectType == 5" class="i_tils elp"> <span>学 制：</span>{{
                                item.academic }} 年</div>

                            <div v-if="item.projectType == 5" class="i_tils elp"> <span>招生人数：</span>{{
                                item.enrollPlan }}</div>

                            <div v-if="item.projectType == 5" class="i_tils elp"> <span>留学国家：</span>{{
                                item.countryNameList }}</div>
                            <div style="width: 100%;" class="i_tils">
                                <span>简章简介：</span>
                                <div style="max-width: 600px;height: 38px;" v-html="ImgSizeLimit(item.detial)"
                                    class="twoEllipsis">
                                </div>
                            </div>
                        </div>
                        <div style="display: flex;flex-wrap: wrap;align-items: flex-start;" v-if="projectType == 1"
                            class="item_detail">
                            <div style="width: 100%;" class="i_til">{{ item.name }}</div>
                            <div style="width: 40%;" class="i_tils elp"> <span>招生类别：</span>{{ item.projectCategoryName
                                }}</div>
                            <div style="width: 40%;" class="i_tils elp"> <span>招生专业：</span>{{ item.majorName }}</div>
                            <div style="width: 40%;" class="i_tils elp"> <span>学习时长：</span>{{ item.academic }}</div>
                            <div style="width: 40%;" class="i_tils elp"> <span>所获证书：</span>{{ item.certificate }}</div>
                        </div>
                    </div>
                    <div class="item_right">
                        <div class="line"></div>
                        <div @click="goNext(`/gxGathers/school/enrollmentGuide?enrollId=${item.id}&id=${id}`)"
                            class="i_btn">查看简章
                        </div>
                    </div>
                </div>
                <el-empty style="margin: 0 auto;" v-if="!lists || lists.length == 0" description="暂无数据"></el-empty>

                <el-pagination background style="display: flex;justify-content: center;" :page-count="4"
                    class="pagination partner-pagination" :current-page="pageNum" :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper" :total="total"
                    @current-change="handleCurrentChange" @size-change="handleSizeChange"
                    :page-sizes="[10, 20, 50, 100]" />

            </div>
        </div>
    </div>
</template>

<script>
import { Know } from "@/api/know";
let know = new Know();
import { getDomain } from "@/api/cookies";
export default {
    components: {},

    data() {
        return {
            id: '',
            projectType: '',
            pageSize: 10,
            pageNum: 1,
            total: 0,
            lists: []
        };
    },
    created() {
        this.id = this.$route.query.id;
        this.projectType = localStorage.getItem('projectType');
        this.selectSchoolBriefsPcList()
    },
    mounted() {

    },
    methods: {
        selectSchoolBriefsPcList() {
            if (this.projectType == 0) {
                know.selectSchoolBriefsPcList({
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    projectType: this.projectType,
                    schoolId: this.id,
                })
                    .then((res) => {
                        this.lists = res.rows
                        this.total = res.total
                    });
            } else if (this.projectType == 5) {
                know.selectBriefsPcAbroadPage({
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    projectType: this.projectType,
                    schoolId: this.id,
                })
                    .then((res) => {
                        this.lists = res.rows
                        this.total = res.total
                    });
            }
            else if (this.projectType == 1) {
                know.selectBriefsPcGraduatePage({
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    projectType: this.projectType,
                    schoolId: this.id,
                })
                    .then((res) => {
                        this.lists = res.rows
                        this.total = res.total
                    });
            }

        },
        handleSizeChange(val) {
            this.pageNum = 1
            this.pageSize = val
            this.selectSchoolBriefsPcList()
        },
        handleCurrentChange(val) {
            this.pageNum = val
            this.selectSchoolBriefsPcList()
        },
    },
};
</script>

<style lang="less" scoped>
.mainbox {
    width: 1200px;
    margin: 0 auto;
    padding: 20px 0;
    padding-bottom: 10px;


    .school_detail {
        position: relative;
        width: 1200px;
        border-radius: 3px 3px 3px 3px;
        padding: 20px;
        background: #fff;


        .line {
            position: absolute;
            width: 6px;
            height: 24px;
            background: #1061FF;
            border-radius: 0px 4px 4px 0px;
            left: 0;
            top: 23px;
        }

        .s_til {
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
            font-weight: bold;
            font-size: 24px;
            color: #444444;
        }

    }

    .detail {
        display: flex;
        padding: 20px;
        background: #fff;


        .detail_list {
            width: 100%;

            .detail_item {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 20px;

                .item_left {
                    display: flex;

                    .i_img {
                        width: 232px;
                        height: 200px;
                    }

                    .item_detail {
                        display: flex;
                        flex-wrap: wrap;
                        margin-left: 20px;

                        >div {
                            width: 50%;
                        }

                        .i_til {
                            width: 100%;
                            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
                            font-weight: normal;
                            font-size: 18px;
                            color: #333333;
                            font-weight: bold;
                            margin-bottom: 10px;

                        }

                        .i_tils {

                            font-family: MicrosoftYaHei, MicrosoftYaHei;
                            font-weight: normal;
                            font-size: 14px;
                            color: #555555;
                            margin-top: 10px;
                            display: flex;

                            span {
                                display: block;
                                font-family: Microsoft YaHei, Microsoft YaHei;
                                font-weight: bold;
                                font-size: 14px;
                                color: #555555;
                                flex-shrink: 0;
                            }
                        }
                    }
                }

                .item_right {
                    display: flex;
                    align-items: center;


                    .line {
                        width: 0px;
                        height: 90px;
                        border: 1px solid #EEEEEE;
                    }

                    .i_btn {
                        width: 138px;
                        height: 40px;
                        background: #1061FF;
                        border-radius: 20px 20px 20px 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-family: MicrosoftYaHei, MicrosoftYaHei;
                        font-weight: normal;
                        font-size: 14px;
                        color: #FFFFFF;
                        cursor: pointer;
                        margin-left: 36px;

                        &:hover {
                            background: #3D6CC8;
                        }
                    }
                }
            }
        }
    }


}

img {
    display: block;
    width: 100%;
    height: 100%;
}
</style>